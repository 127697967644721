// Define your custom variables after Bootstrap imports
$primary: #064055;
$secondary: #F0F3FB;
$tertiary: #e0e8fb;
$blue: #4a90e2;
$white: #fff;
$header-spacer: #cccccc;
$header-links: #333333;
$box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
$box-shadow-sm: 0px 2px 4px rgba(0, 0, 0, 0.75);
$box-shadow-lg: 0px 16px 48px rgba(0, 0, 0, 0.176);
$box-shadow-activity: 0px 2px 4px rgba(0, 0, 0, 0.075);
$body-color: #292b2c;
$table-color: $body-color;
$body-bg: #f5f5f5;
$text-muted: #767676;
$pink: #cf3a73;

*{
font-family: "Arial, sans-serif"
}

.border-right {
    border-right: 3px solid $secondary;
}

.gray-text{
    color: $text-muted;
}

.primary-text{
    color: $primary
}

.saved-card-container {
  font-family: Arial, sans-serif;
  margin: 20px 0;
}

.saved-card-header {
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.card-item {
  width: 400px;
  height: 195px;
  max-width: 100%;
  font-size: x-large;
  background-color: #007bffba;
  border-radius: 10px;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.card-icons {
  display: flex;
  justify-content: space-between;
}

.card-icon {
  font-size: 1.5rem;
}

.delete-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.card-number {
  letter-spacing: 2px;
  text-align: left;
  margin-top: 10px;
}

.card-details {
  display: flex;
  justify-content: space-between;
}

.add-card-btn {
  margin-top: 15px;
  color: #6b44c4;
  font-size: 1rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-card-btn i {
  font-size: 1rem;
}

.img-box{
    position: relative;
    border-radius: 50%;
    background-color: $tertiary;
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

  img{
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .camera-box{
    position: absolute;
    bottom: 5%;
    right: 0;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  }
}

.progress-outer-circle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;

  .progress-inner-circle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;
  }
}

.block-letter{
  text-transform: uppercase;
}

.back-button{
    z-index: 99;
    border: .8em solid $header-spacer;
    justify-content: center;
    align-items: center;
    height: 2.8em;
    width: 2.8em;
    display: flex;
    margin: 30px 30px 15px 30px;
    position: fixed;
    border-radius: 50%;
    background: blue;
}

.trip-heading{
  color: $header-links;
  margin: 30px 30px 15px 90px;
  font-size: 1.1em;
  font-weight: 600;
}

.travel-card{
  // background-color: $secondary;
  border-radius: 10px;
  overflow: visible;

  .card-img{
    border-radius: 8px;
    height: 32vh;
    max-height: 90%;
    width: 17vw;
    object-fit: cover;
  }

  .tag{
      border-radius: 5px;
      text-align: center;
      position: absolute;
      left: 53%;
      top: -10px;
      width: 100px;
      height: 25px;
      font-size: 1em;
      color: $white;
      background-color: $primary;
  }

  .back-button{
    border: .5em solid $header-spacer;
    margin: 0;
    right: 10px;
    bottom: 10px;
    height: 2em;
    width: 2em;
    position: absolute;
  }
}

.sub-heading {
  color: $header-links;
  font-size: 1.3em;
  font-weight: 600;
}

.banner-image{
  padding: 0;
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  p{
    position: absolute;
    color: $white;
    font-size: 2em;
    font-weight: 600;
  }
}

.black-overlay{
  background: rgba(0, 0, 0, 0.5);;
  width: 100%;
  height: inherit;
  position: absolute;
}

.accordion-header{
  background: $primary;
  color: $white;
}

.input-box-icon{
  color: $text-muted;
  left: 5%;
}

.background-patch{
  height: min-content;
  white-space: nowrap;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: $header-spacer;
  color: $primary;
  font-size: 14px;
}