.reasonCard {
  width: 100vw !important;
  min-height: calc(0.4 * 100vw) !important;
  max-height: calc(0.4 * 100vw) !important;

  // @media only screen and (min-width: 767px) and (max-width: 1000px) {
  //   min-height: 50% !important;
  //   max-height: 50% !important;
  // }
  // @media only screen and (min-width: 500px) and (max-width: 767px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (min-width: 400px) and (max-width: 500px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (max-width: 400px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
}
.img {
  width: 100vw !important;
  min-height: calc(0.3 * 100vw) !important;
  max-height: calc(0.3 * 100vw) !important;

  // @media only screen and (min-width: 767px) and (max-width: 1000px) {
  //   min-height: 50% !important;
  //   max-height: 50% !important;
  // }
  // @media only screen and (min-width: 500px) and (max-width: 767px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (min-width: 400px) and (max-width: 500px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (max-width: 400px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
}
