.inputBorder {
  border-bottom: 1px solid black !important;
  border: none !important;
}

.heading1{
  color:#064055; font-weight: 600; font-size: 16px; line-height: 23px; margin:0 0 20px;
}

.process{
  display: grid;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}

.list{
  box-shadow: 0 0 10px #ccc; display: grid; gap:10px;
  border-radius: 5px; padding: 10px; grid-template-columns: 1fr 10fr;

} 