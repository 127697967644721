@import "../../../styles/variables";

.header {
  color: $white;
  position: relative;
  z-index: 3;
  p {
    margin-bottom: 0;
  }
}
.currentPage {
  border-left-color: $header-spacer;
  border-style: solid;
  height: 47px;
  border-width: 0;
  margin-left: 1.25rem;
  // padding-left: 1.25rem;
}
.linkContainer {
  a {
    color: $white;
  }
}
.link {
  color: $header-links;
  margin-right: 1.6875rem;
  text-decoration: none;
}
.dot {
  background:-webkit-linear-gradient( #009f2f, #009f2f,#eee,#eee, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}