.reasonCard {
  min-height: 170px !important;
  max-height: 170px !important;

  // @media only screen and (min-width: 767px) and (max-width: 1000px) {
  //   min-height: 10% !important;
  //   max-height: 10% !important;
  // }
  // @media only screen and (min-width: 500px) and (max-width: 767px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (min-width: 400px) and (max-width: 500px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
  // @media only screen and (max-width: 400px) {
  //   min-height: 0px !important;
  //   max-height: 300px !important;
  // }
}
