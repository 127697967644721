.tourSection {
  height: fit-content;
  background: url(https://drive.google.com/thumbnail?sz=w2000&id=1koCg4wEWN0g9-rVmFGG-5EwXEle0qqry);
  background-repeat: no-repeat;
  padding-top: 30px !important;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.content {
  width: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.582);
  color: white;
  transform: translateY(10%);
  transition: all 0.35s ease;
}
.tourSection :hover .content {
  width: 100%;
  transform: translateY(0);
}