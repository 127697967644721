.imageSection {
  height: 700px !important;
  @media only screen and (max-width: 300px) {
    padding: 8px !important;
  }
  h1 {
    font-size: 78px;
    @media only screen and (min-width: 500px) and (max-width: 767px) {
      font-size: 58px;
    }
    @media only screen and (min-width: 400px) and (max-width: 500px) {
      font-size: 48px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 38px;
    }
  }
  p {
    font-size: 24px;
    @media only screen and (min-width: 500px) and (max-width: 767px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 400px) and (max-width: 500px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 12px;
    }
  }

  button {
    font-size: 24px;
    justify-self: center;
    @media only screen and (min-width: 500px) and (max-width: 767px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 400px) and (max-width: 500px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 12px;
    }
  }
}
.bharatImage {
  z-index: 0;
  background-image: url(https://drive.google.com/thumbnail?sz=w2000&id=1kewbHG0osK-sjqm-PqjAFEcp5wte2vjT);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: unset;
  width: 100%;
  height: 25%;
  bottom: 0px !important;
}
.bgBluePink {
  background-image: linear-gradient(to top right, #fed8f7, #c4f1fe);
  outline: white;
}
.reasonCard {
  min-width: 450px !important;
  max-height: 350px !important;
  min-height: 350px !important;
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    min-width: 300px !important;
    min-height: 230px !important;
    max-height: 230px !important;
  }
  @media only screen and (min-width: 500px) and (max-width: 767px) {
    min-width: 100% !important;
    min-height: 325px !important;
  }
  @media only screen and (min-width: 400px) and (max-width: 500px) {
    min-width: 100% !important;
    min-height: 300px !important;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100% !important;
    min-height: 300px !important;
  }
}
