body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, canva, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Norwester", "dm sans", sans-serif, "sans", League Gothic (italic);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
label {
  font-weight: 600;
  color: #666;
}
body {
  color: #737373 !important;
  /* font-family: sans-serif !important; */
}
.box8 {
  box-shadow: 0px 0px 5px 1px #999;
}
.mx-t3 {
  margin-top: -3rem;
}