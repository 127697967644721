.card {
  max-height: 630px;
  border-radius: 3px !important;
}
.navigationButton {
  border-radius: 50px !important;
  // float: right;
}
.btmRght {
  right: 10px;
  bottom: 10px;
}
.row > * {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 0px !important;
}
svg {
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
}
.routeTimeline {
  max-height: 170px !important;
}
.imageSection {
  height: 700px !important;
  @media only screen and (max-width: 300px) {
    padding: 8px !important;
  }
  h1 {
    font-size: 78px;
    @media only screen and (min-width: 500px) and (max-width: 767px) {
      font-size: 58px;
    }
    @media only screen and (min-width: 400px) and (max-width: 500px) {
      font-size: 48px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 38px;
    }
  }
}

.aboutbg{
  background: url(../../../Assets/about-usbg.jpg);
  width: 100%;
  padding: 15% 0 7%;
  position: relative;
}

.aboutbg:before{
  content: "";
  background: rgba(0,0,0,0.72);
  position: absolute;
  width: 100%;
  height: 100%;
  left:0; top:0
}

.abouttitle{
  font-weight: 700; color: #fff; font-size: 36px !important; text-align: center;
  position: relative;
}

.tourSection {
  height: fit-content;
  background: url(https://drive.google.com/thumbnail?sz=w2000&id=1GYr0pCkQFcd9RV0QBH8DPbHBQFvYiyQA);
  background-repeat: no-repeat;
  padding-top: 30px !important;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

div.teamcard{
  box-shadow: none; border-radius: 0;
}

.teamimage{
  border-radius: 14px; box-shadow: 0 0 5px #fbfbfb;
}

.teamname{
  color: #064055; font-size: 21px; font-weight: 700; margin:0; padding-left: 0;
}

.span{
  color: #064055; font-size: 18px; font-weight: 400;
}

.redoutlinebttn{
  padding: 10px 20px; border-radius:25px; border:1px solid #EA3560; margin-top:20px;
  color: #000000; font-size: 15px; font-weight: 500; text-decoration: none;;
}

.redoutlinebttn:hover{
  background: #EA3560; color: #fff;
}

.box{
  background: #fbfbfb; border-radius: 15px; box-shadow: 0 0 10px #cccccc;
  padding: 40px 15px; margin-bottom: 20px; padding-left: 18px !important;
}
.cycle{
  padding: 10px; height: 50px; width: 50px; border-radius: 100%; box-shadow: 0 0 10px #cccccc;
}