$primary: #006ead;
$secondary: #96a3ae;
$white: #fff;
$header-spacer: #cccccc;
$header-links: #333333;
$box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
$box-shadow-sm: 0px 2px 4px rgba(0, 0, 0, 0.75);
$box-shadow-lg: 0px 16px 48px rgba(0, 0, 0, 0.176);
$box-shadow-activity: 0px 2px 4px rgba(0, 0, 0, 0.075);
$body-color: #292b2c;
$table-color: $body-color;
$body-bg: #f5f5f5;
$text-muted: #767676;
$bs-btn-bg: #EA3560
