.mv-5{
    margin-top: 5px;
    margin-bottom: 5px;
}

.mh-5 {
    margin-right: 5px;
    margin-left: 5px;
}

.color-dark-primary{
    color: #064055 !important;
}

.bg-dark-primary {
    background: #064055 !important;
}

.color-blue {
    color: #4a90e2 !important;
}

.bg-blue {
    background: #4a90e2 !important;
}

.bg-baby-blue{
    background: #F0F3FB !important;
}

.bg-pink{
    background: #cf3a73 !important;
}

.color-pink {
    color: #cf3a73 !important;
}

.color-white {
    color: #fff !important;
}

.bg-white {
    background: #fff !important;
}

.p-5{
padding: 5px;
}

.overflow-h{
overflow: hidden;
}

.row-flex{
    display: flex;
    flex-direction: row;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
}

.fs-lg{
    font-size: large
}

.cursor-pointer{
    cursor: pointer;
}