.bgBluePink {
  background-image: linear-gradient(to top right, #fed8f7, #c4f1fe);
  outline: white;
}

// @media (max-width: 768px) {
//   div[ref='containerRef'] {
//     justify-content: start;
//   }

//   img {
//     height: 120px ;
//   }

//   div[style*="width: 200px"] {
//     width: 150px !important;
//   }
// }
@media (max-width: 768px) {
  .slider-img{
    justify-content: center;
    gap: 10px;
  }
  .slider-img-item {
    width: 150px !important;
  }
}