.card {
  // max-height: 630px;
  border-radius: 3px !important;
}

.navigationButton {
  border-radius: 50px !important;
  // float: right;
}

.cardview{
  position: absolute;
  width: 30%;
  right: 20px;
  padding: 0;
  top: calc(100% - 98px);
  z-index: 3;
  
}

span.medium{
  display: block; text-align: center;
  background: #1F576B;
  color:#fff; border-radius: 16px 16px 0 0;
  font-size: 36px; padding: 22px 0;
}

.bodyPart{
  background: #F0F3FB;border-radius:  0 0 16px 16px;
  text-align: center; padding:20px 30px 50px;
}

.price{
  color:#EA3560; font-size:30px; text-align: center; padding: 20px 0;
  width: 100%;
  border: 0;
  box-shadow: none;
}

span.checkboxsec svg{
  color:#EA3560; 
}

.shortlabel{
  color: #064055; font-size:15px; font-weight: 500; display: block;
  text-align: left; margin:30px 0 10px;
}

.tourtitle{
  color:#064055;
  font-size:33px; font-weight: 800; margin:40px 0 15px;

}

.datefield{
  background: #fff; border-radius:5px; padding: 8px;width: 100%;
  box-shadow: none;
  border: 0;
  margin: 0 0 20px;
}

.slick-slider{
  position: relative;
}

.commanheading{
  font-size: 16px; font-weight: 700; color: #064055;
}

.aboutpara{
  font-size: 14px; color:#6c6c6c; line-height: 20px;
}

.view{
  font-size:13px; line-height: 16px; color:#EF658D; text-decoration: none; border:0; padding: 0;
  background: none;
}

.view:hover{color:#EF658D; background: none;}

.bookNow{
  width: 100%; border-radius: 30px; padding: 10px 20px; display: block;
  background: #064055; color:#fff; font-size: 18px; font-weight: 500;
  text-decoration: none;
}

.subhead{
  font-weight: 700; color: #28332D; font-size: 14px;
}

.btmRght {
  right: 10px;
  bottom: 10px;
}
.row > * {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 0px !important;
}
svg {
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
}
.routeTimeline {
  height: 500px !important;
}

.rivewCard {
  height: 600px !important;
}
.tourNameHeight{
  min-height: 9rem !important;
}

@media (max-width:990px){

  .cardview{
    position: static;
    width: 100%;
    
  }


}