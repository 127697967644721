// .carouselCard {
//   // width: 382px;
//   min-height: 520px;
//   position: relative;
//   margin: 5; /* No gap */
//   opacity: 1; /* Fully visible */
//   border-radius: 15px!important;
// }

// .cardImage {
//   width: 100%;
//   height: 100%;
//   object-fit: cover; /* Ensure image covers the entire area */
//   padding: 0; /* No padding */
//   margin: 0; /* No margin */
// }

// .cardTextOverlay {
//   position: relative;
//   padding: 10px;
//   color: black;
//   background: white;
//   border: 1px solid #ddd; /* Light border for separation */
// }
// .mobileContainer {
//   padding: 0 !important; /* Remove padding from the container */
//   margin: 0 auto; /* Center the container */
//   width: 100%; /* Ensure full width for responsiveness */
// }

// // @media (max-width: 768px) {
// //   .carouselCard {
// //     margin-left: auto;
// //     margin-right: auto;
// //     width: 90%; /* Adjust width for better responsiveness */
// //   }

// //   .mobileContainer {
// //     padding-left: 0 !important;
// //     padding-right: 0 !important;
// //     margin: 0 auto;
// //   }
// // }
// @media (max-width: 768px) {
//   .carouselCard {
//     margin: 10px auto; /* Add equal margin for consistent spacing */
//     width: 90%; /* Make the card width responsive */
//   }

//   .scrolling-wrapper {
//     padding: 10px 0; /* Add vertical padding for better spacing */
//     justify-content: center; /* Center cards horizontally */
//   }

//   .mobileContainer {
//     padding: 0 15px; /* Add padding to prevent content from touching the screen edges */
//     width: 100%; /* Full container width */
//   }
// }


// .cardImage {
//   transition: transform 0.3s ease; /* Smooth hover effect */
// }

// .cardImage:hover {
//   transform: scale(1.05); /* Slight zoom on hover */
// }

// .customCard {
//   transition: box-shadow 0.3s ease;
// }

// .customCard:hover {
//   box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
// }


// ====

/* Fix the card layout */
.carouselCard {
  width: 100%; /* Card will adapt to the container's width */
  max-width: 320px; /* Restrict the card's maximum size */
  min-height: 520px;
  position: relative;
  margin: 10px auto; /* Uniform margin for spacing */
  border-radius: 15px !important; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Adjust the card image */
.cardImage {
  width: 100%;
  height: 290px; /* Set a fixed height for images */
  object-fit: cover; /* Prevent image distortion */
  margin: 0; /* Remove extra margins */
}

/* Card text overlay styles */
.cardTextOverlay {
  padding: 15px;
  background: white;
  border-top: 1px solid #ddd; /* Add a light border to separate text */
}

/* Wrapper for horizontal scrolling */
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px; /* Consistent space between cards */
  overflow-x: auto;
  padding: 10px; /* Padding inside the scrollable area */
  margin: 0 auto;
}

.scrolling-wrapper::-webkit-scrollbar {
  height: 8px; /* Customize scrollbar height */
}

.scrolling-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Scrollbar color */
  border-radius: 4px;
}

/* Adjust layout for mobile screens */
@media (max-width: 768px) {
  .carouselCard {
    width: 90%; /* Adjust card width for small screens */
    margin: 15px auto; /* Add extra spacing for smaller devices */
  }

  .mobileContainer {
    padding: 0 15px; /* Prevent content from touching edges */
  }

  .scrolling-wrapper {
    gap: 10px; /* Reduce gaps between cards */
    padding: 0 10px; /* Smaller padding for smaller screens */
  }
}

.button-mobile {
  font-size: 12px;
}

.button-desktop {
  font-size: 15px;
}