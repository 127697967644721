.tourSection {
  height: fit-content;
  background:
    url(https://drive.google.com/thumbnail?sz=w2000&id=1kjRSSTaVE3Y3CJE_ydulSZFoy2yf_h6W);
  background-repeat: no-repeat;
  padding-top: 30px !important;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
